const COLORS = {
  primary: '#7D2EA1',
  textColor: '#545454',
  mutedGrey: '#C4C9D4',
  reallyLightGrey: '#F9F9F9',
  twitterBlue: '#1da1f2',
  linkedInBlue: '#2867b2',
  lightGrey: '#E5E5E5',
  white: 'white',
};

const breakpoints = {
  xxxs: '330px',
  xxs: '544px',
  xs: '768px',
  sm: '1088px',
  md: '1216px',
  lg: '1408px',
  xl: '1600px',
  xxl: '2000px',
  xxxl: '2200px',
};

const Theme = {
  breakpoints,
  colors: COLORS,
  components: {
    Card: {
      variants: {
        default: {
          backgroundColor: 'white',
          borderColor: '#d9dadc',
        },
      },
    },
    Button: {
      variants: {
        white: {
          backgroundColor: COLORS.white,
          boxShadow: 'none',
          color: COLORS.textColor,
          '&:hover': {
            color: COLORS.textColor,
            backgroundColor: COLORS.white,
          },
        },
      },
    },
    ResponsiveTableContainer: {
      variants: {
        default: {
          border: 'none',
          borderRadius: 4,
          background: COLORS.reallyLightGrey,
        },
      },
    },
    ResponsiveTableRow: {
      variants: {
        default: {
          '&:last-child': {
            border: 'none',
          },
          [`@media(max-width: ${breakpoints.sm})`]: {
            display: 'block',
          },
        },
      },
    },
    Checkbox: {
      variants: {
        default: {
          checkmarkColor: 'white',
          borderHoverColor: COLORS.primary,
          backgroundColorOff: COLORS.reallyLightGrey,
          shadowColorOn: COLORS.primary,
          borderColorOn: COLORS.primary,
          backgroundColorOn: COLORS.primary,
        },
      },
    },
    Input: {
      variants: {
        bottomBorder: {
          shadowColor: 'none',
          focusShadowColor: 'none',
          shadowErrorColor: 'none',
          focusBorderColor: 'transparent',
          borderColor: 'transparent',
        },
      },
    },
    Loadable: {
      variants: {
        default: {
          defaultAnimation: 'ring',
          color: COLORS.primary,
        },
      },
    },
  },
};

export default Theme;

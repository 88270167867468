import React from 'react';

const DisableSSR = ({ children }) => {
  const isSSR = typeof window === 'undefined';

  if (isSSR) {
    return null;
  }

  return <React.Suspense fallback={<div />}>{children}</React.Suspense>;
};

export default DisableSSR;

import { Loadable } from '@actovos-consulting-group/ui-core';
import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import { pick } from 'lodash';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import fbConfig from './config';
import AuthContext from './context';

const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [authUser, setAuthUser] = useState({});
  const [user, setUser] = useState({});

  const initFirebase = () => {
    if (firebase.apps.length > 0 || typeof window === 'undefined') {
      return;
    }

    mixpanel.init('5d7e90ce44dd316c338fed84e7b5976d', {
      api_host: 'https://api.mixpanel.com',
    });

    if (process.env.GATSBY_FIREBASE_MOCK) {
      mixpanel.opt_out_tracking();
      firebase.initializeApp({
        ...fbConfig,
        storageBucket: 'default-bucket',
        projectId: 'roast-or-toast-app',
      });
      const db = firebase.firestore();
      db.useEmulator('localhost', 8080);

      const storage = firebase.storage();
      storage.useEmulator('localhost', 9199);

      firebase.functions().useEmulator('localhost', 5001);
      firebase.auth().useEmulator('http://localhost:9099');
    } else {
      firebase.initializeApp(fbConfig);
      mixpanel.track('Initial Load');
      firebase.analytics();
    }

    firebase.auth().onAuthStateChanged(async u => {
      setAuthUser(u);

      if (u) {
        const doc = await firebase
          .firestore()
          .collection('Users')
          .doc(u.uid)
          .get();

        if (doc.exists) {
          const payload = { ...doc.data(), doc, id: doc.id };
          mixpanel.identify(doc.id);
          mixpanel.people.set({
            email: payload.email,
            name: payload.name,
            username: payload.username,
          });
          setUser(payload);
        }
      } else {
        setUser({});
      }

      setLoading(false);
    });
  };

  useEffect(() => {
    if (authUser?.uid) {
      firebase
        .firestore()
        .collection('Users')
        .doc(authUser.uid)
        .onSnapshot(doc => {
          setUser({ ...doc.data(), doc, id: doc.id });
        });
    }
  }, [authUser]);

  useEffectOnce(() => {
    initFirebase();
  });

  const actions = {
    updateUser: data =>
      // only allow certain fields to update
      user.doc.ref.set(pick(data, ['name', 'bio', 'highlights']), {
        merge: true,
      }),
  };

  return (
    <Loadable loading={!firebase.apps.length || loading}>
      <AuthContext.Provider value={{ authUser, user, actions }}>
        {children}
      </AuthContext.Provider>
    </Loadable>
  );
};

export default AuthProvider;

import { createContext, useContext } from 'react';

const initialState = {};

const AuthContext = createContext(initialState);

export const useAuthContext = () => {
  const auth = useContext(AuthContext);

  return auth;
};

export default AuthContext;

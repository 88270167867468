exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-index-js": () => import("./../../../src/pages/app/index.js" /* webpackChunkName: "component---src-pages-app-index-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-submit-index-js": () => import("./../../../src/pages/submit/index.js" /* webpackChunkName: "component---src-pages-submit-index-js" */),
  "component---src-pages-user-[slug]-js": () => import("./../../../src/pages/user/[slug].js" /* webpackChunkName: "component---src-pages-user-[slug]-js" */),
  "component---src-templates-contentful-page-js": () => import("./../../../src/templates/contentful-page.js" /* webpackChunkName: "component---src-templates-contentful-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}


import { createGlobalStyle, css } from 'styled-components';
import styledNormalize from 'styled-normalize';

const GlobalStyle = createGlobalStyle(
  ({ theme }) => css`
    ${styledNormalize}

    #___gatsby,#gatsby-focus-wrapper {
      height: 100%;
    }

    html,
    body {
      font-family: 'Maven Pro', sans-serif !important;
      height: 100%;
      background: white;
      color: ${theme.colors.textColor} !important;
    }

    body {
      display: flex;
      flex-direction: column;
    }

    * {
      box-sizing: border-box;
    }

    a[variant='default'],
    a[variant='white'] {
      text-decoration: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-transition-delay: 9999s;
      -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
    }

    .lightbox-title-content {
      display: none;
    }
  `,
);

export default GlobalStyle;

import { Flex, Provider, Toaster } from '@actovos-consulting-group/ui-core';
import '@fontsource/maven-pro/400.css';
import '@fontsource/maven-pro/500.css';
import '@fontsource/maven-pro/600.css';
import '@fontsource/maven-pro/700.css';
import '@fontsource/maven-pro/800.css';
import '@fontsource/maven-pro/900.css';
import React from 'react';
import DisableSSR from './src/components/disable-ssr';
import AuthProvider from './src/firebase/provider';
import GlobalStyle from './src/global-styles';
import Theme from './src/theme';

export const wrapRootElement = ({ element }) => {
  return (
    <AuthProvider>
      <Provider theme={Theme}>{element}</Provider>
    </AuthProvider>
  );
};

export const wrapPageElement = ({ element }) => (
  <Flex flexDirection="column" height="100%">
    <GlobalStyle />
    {element}
    <DisableSSR>
      <Toaster.Provider />
    </DisableSSR>
  </Flex>
);
